@use "../../styles/variables" as *;

// override default
.react-tooltip {
  max-width: 400px;
  z-index: 999;
}

// custom
.react-tooltip span {
  font-weight: bold;
}

.tooltip-container {
  background-color: $yellow !important;
  color: $black !important;
  box-shadow: $soft-shadow !important;
  font-size: 20px !important;
}
