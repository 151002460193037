.container-navbar {
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 10;
}

.responsive-ms {
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .responsive-ms {
    margin-left: 5rem;
  }
}
.navbar-link a {
  color: #000000 !important;
  text-decoration: none !important;
}

.navbar-link:hover {
  text-decoration: underline !important;
}/*# sourceMappingURL=Navbar.css.map */