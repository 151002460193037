html,
body {
  background-color: #ffffff;
  margin: 0px;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

p {
  font-size: 16px;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.font-grey {
  color: #cccccc;
}/*# sourceMappingURL=App.css.map */