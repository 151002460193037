@use "../../styles/variables" as *;

.container-sidebar {
  background-color: $white;
  height: 100vh;
  box-shadow: $soft-shadow;
  width: 80px;
  z-index: 11;
}

.sidebar-icon {
  width: 100%;
}

.nav-link.active .sidebar-icon {
  background-color: $yellow;
}

.nav-link {
  transition: all 0.5s ease-in-out;
}

.calendar-container {
  border: 1px solid $grey;
}
