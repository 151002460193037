@use "../../styles/variables" as *;

.custom-tooltip-container {
  background-color: rgba($color: $white, $alpha: 0.96);
  border: 1px solid $grey;
  z-index: 9999 !important;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.recharts-default-legend li span {
  color: $black !important;
}
