.container-alert {
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  transition: ease-in-out 0.5s !important;
}

.container-alert.pl-expanded {
  padding-left: 80px !important;
}

.container-alert.pl-not-expanded {
  padding-left: 80px !important;
}

.alert-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn-close:focus {
  box-shadow: none !important;
}/*# sourceMappingURL=Alert.css.map */