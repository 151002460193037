@use "../../styles/variables" as *;

.refreshing-container {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: $white;
  color: $black;
  border-radius: 4px;
  box-shadow: $hover-shadow;
  text-align: center;
}

.refreshing-container.ml-expanded {
  margin-left: 80px; //half sidebar
}

.refreshing-container.ml-not-expanded {
  margin-left: 80px; //half sidebar
}
