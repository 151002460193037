.refreshing-container {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}

.refreshing-container.ml-expanded {
  margin-left: 80px;
}

.refreshing-container.ml-not-expanded {
  margin-left: 80px;
}/*# sourceMappingURL=Refreshing.css.map */