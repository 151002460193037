.separator-container {
  width: 100%;
}

.separator {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 10px;
  background-color: #cccccc;
  transform: skew(-20deg);
  opacity: 0.3;
}/*# sourceMappingURL=Separator.css.map */