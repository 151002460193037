@use "../../styles/variables" as *;

.container-navbar {
  background-color: $white;
  box-shadow: $soft-shadow;
  z-index: 10;
}

.responsive-ms {
  margin-left: 1rem;
}

$md: map-get($grid-breakpoints, md);
@media (min-width: $md) {
  .responsive-ms {
    margin-left: 5rem;
  }
}

.navbar-link a {
  color: $black !important;
  text-decoration: none !important;
}

.navbar-link:hover {
  text-decoration: underline !important;
}
