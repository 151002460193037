@use "../../styles/variables" as *;

.container-calendar {
  position: absolute;
  left: 0px;
  top: 25px;
  box-shadow: $soft-shadow;
  background-color: $white;
  border: 1px solid $grey;
  cursor: default;
}

// override react-calendar styles
.react-calendar {
  border: none !important;
  font-family: "Barlow Condensed" !important;
  font-size: 20px !important;
}

.react-calendar__tile--now {
  background-color: $white !important;
  font-weight: bold !important;
}

.react-calendar__tile--hasActive {
  background-color: $yellow !important;
}
