@use "../../styles/variables" as *;

.small-title-container {
  position: relative;
  padding: 20px;
  width: fit-content;
  min-width: 160px;
  max-width: 400px;
}

.small-title-text {
  position: relative;
  z-index: 2;
}

.yellow-shape {
  position: absolute;
  background-color: $yellow;
  z-index: 1;

  &.a {
    width: 20px;
    height: 40px;
    top: 20px;
    right: 50px;
  }

  &.b {
    width: 20px;
    height: 40px;
    top: 0;
    right: 25px;
  }

  &.c {
    width: 20px;
    height: 40px;
    top: 10px;
    right: 0;
  }

  &.d {
    width: 80px;
    height: 20px;
    top: 40px;
    right: 20px;
  }

  &.e {
    width: 20px;
    height: 60px;
    top: 0;
    right: 100px;
  }

  &.f {
    width: 20px;
    height: 40px;
    top: 0;
    right: 75px;
  }

  &.g {
    width: 80px;
    height: 20px;
    top: 20px;
    right: 40px;
    transform: skew(-20deg);
  }
}
