.container-sidebar {
  background-color: #ffffff;
  height: 100vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 80px;
  z-index: 11;
}

.sidebar-icon {
  width: 100%;
}

.nav-link.active .sidebar-icon {
  background-color: #f8e905;
}

.nav-link {
  transition: all 0.5s ease-in-out;
}

.calendar-container {
  border: 1px solid #cccccc;
}/*# sourceMappingURL=Sidebar.css.map */