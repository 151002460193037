@use "variables" as *;

// Bootstrap customization
@import "../../node_modules/bootstrap/scss/functions";

// Override default variables and mixins
$body-color: $black; // basic text color
$spacer: 2rem; // spacing basic unit
$font-family-base: "Barlow Condensed"; // font
$font-family-sans-serif: "Barlow Condensed", system-ui, -apple-system,
  "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-monospace: "Barlow Condensed", SFMono-Regular, Menlo, Monaco,
  Consolas, "Liberation Mono", "Courier New", monospace;
$input-border-color: $grey; // form border
$input-focus-bg: none; // form highlight on focus
$input-focus-border-color: none; // form highlight on focus
$input-focus-color: none; // form highlight on focus
$input-focus-width: 0; // form highlight on focus
$input-focus-box-shadow: none; // form highlight on focus
$input-btn-focus-box-shadow: none; // form highlight on focus
$form-select-indicator-color: $grey; // select chevron color
$form-select-padding-y: 1rem; // select padding
$input-group-addon-bg: $white; // icons in input group
$input-group-addon-border-color: rgba(
  $grey,
  1
); // border of icons in input group
$border-radius: 0rem;
$form-check-input-checked-bg-color: $yellow; // switch active bg
$form-switch-focus-color: rgba(black, 0.25); // switch button on focus
$form-check-input-focus-border: none; // switch on focus
$form-check-input-focus-box-shadow: none; // switch on focus

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Generate custom colors components
$custom-colors: (
  "primary-yellow": $yellow,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../../node_modules/bootstrap/scss/bootstrap";
