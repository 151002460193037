.page-login {
  min-height: 100vh;
  background-image: url("./bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
}

.container-login {
  width: 50%;
  min-width: 100px;
  max-width: 300px;
}

.logo-login {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
}

.forgot-pw:hover {
  text-decoration: underline;
}

.condition-list li {
  opacity: 0.5;
}

.condition-list li.active {
  opacity: 1;
}/*# sourceMappingURL=Login.css.map */