$black: #000000;
$white: #ffffff;
$yellow: #f8e905;
$grey: #cccccc;

$complementaryBlue: #5005f7;
$similarOrange: #fcba08;

$soft-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
$hover-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
