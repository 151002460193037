.react-tooltip {
  max-width: 400px;
  z-index: 999;
}

.react-tooltip span {
  font-weight: bold;
}

.tooltip-container {
  background-color: #f8e905 !important;
  color: #000000 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  font-size: 20px !important;
}/*# sourceMappingURL=TooltipWrapper.css.map */