@use "../../styles/variables" as *;

.main-layout {
  background-color: $white;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  min-width: fit-content;
}
