@use "../../styles/variables" as *;

.kpi {
  width: 80%;
  height: 140px;
  background-color: $yellow;

  &.right {
    mix-blend-mode: multiply;
  }
}

$lg: map-get($grid-breakpoints, lg);
@media (min-width: $lg) {
  .kpi.left {
    width: 30%;
  }

  .kpi.right {
    width: 30%;
    transform: translate(-20px, -20px);
  }
}

@media (max-width: $lg) {
  .kpi.left {
    margin-bottom: 3rem;
  }

  .kpi.left,
  .kpi.right {
    margin-left: auto;
    margin-right: auto;
  }
}

.kpi-text-container-left {
  margin-left: 20px;
  transform: translate(0, 30px);
}

.kpi-value {
  font-size: 96px;
  font-weight: 600;
  line-height: 96px;
  margin-top: 0.5rem;
}

.kpi-subtitle {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 1rem;
}

.kpi-text-container-right {
  margin-left: 40px;
  transform: translate(0, 30px);
}
