.custom-tooltip-container {
  background-color: rgba(255, 255, 255, 0.96);
  border: 1px solid #cccccc;
  z-index: 9999 !important;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.recharts-default-legend li span {
  color: #000000 !important;
}/*# sourceMappingURL=RiqCharts.css.map */