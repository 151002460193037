.container-calendar {
  position: absolute;
  left: 0px;
  top: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  cursor: default;
}

.react-calendar {
  border: none !important;
  font-family: "Barlow Condensed" !important;
  font-size: 20px !important;
}

.react-calendar__tile--now {
  background-color: #ffffff !important;
  font-weight: bold !important;
}

.react-calendar__tile--hasActive {
  background-color: #f8e905 !important;
}/*# sourceMappingURL=CalendarModal.css.map */