.small-title-container {
  position: relative;
  padding: 20px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 160px;
  max-width: 400px;
}

.small-title-text {
  position: relative;
  z-index: 2;
}

.yellow-shape {
  position: absolute;
  background-color: #f8e905;
  z-index: 1;
}
.yellow-shape.a {
  width: 20px;
  height: 40px;
  top: 20px;
  right: 50px;
}
.yellow-shape.b {
  width: 20px;
  height: 40px;
  top: 0;
  right: 25px;
}
.yellow-shape.c {
  width: 20px;
  height: 40px;
  top: 10px;
  right: 0;
}
.yellow-shape.d {
  width: 80px;
  height: 20px;
  top: 40px;
  right: 20px;
}
.yellow-shape.e {
  width: 20px;
  height: 60px;
  top: 0;
  right: 100px;
}
.yellow-shape.f {
  width: 20px;
  height: 40px;
  top: 0;
  right: 75px;
}
.yellow-shape.g {
  width: 80px;
  height: 20px;
  top: 20px;
  right: 40px;
  transform: skew(-20deg);
}/*# sourceMappingURL=Title.css.map */